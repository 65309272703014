import {Component, Input} from '@angular/core';
import {contextColors} from '../../@theme/theme-colors';

@Component({
    selector: 'app-illustration-message',
    template: `
        <div class="text-center">
            <app-illustration [icon]="icon" [iconName]="iconName" [hasTrees]="hasTrees" [circleColor]="circleColor"></app-illustration>
            <div class="text-primary my-auto" style="max-width: 600px">
                <h3 style="font-weight: 800; font-size: 2.5em; text-transform: uppercase;">{{title}}</h3>
                <p style="font-weight: 700" class="text-muted">{{caption}}</p>
                <ng-content></ng-content>
            </div>
        </div>
    `,
    styleUrls: ['./illustration-message.component.scss'],
})
export class IllustrationMessageComponent {
    @Input() title: string;
    @Input() caption: string;
    @Input() iconName: string;
    @Input() icon: {far: any; fas: any};
    @Input() hasTrees = false;
    @Input() circleColor = [contextColors.primary, .15];
}
